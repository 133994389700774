import React, { useEffect, useMemo, useRef, useState } from "react";
import metamask from "../../assets/images/metamask.svg"
import rightArrow from "../../assets/images/right_arrow.svg"
import CopyToClipboard from "react-copy-to-clipboard";
import { getPaymentInfo, savePaymentInfo } from "./service";
import { useParams, useSearchParams } from "react-router-dom";
import Loader from "../shared/loader";
import AppAlert from "../shared/appAlert";
import AppText from "../shared/appText";
import { parseUnits, Contract, BrowserProvider } from "ethers";
import networkConfig, { chainsConfig } from "./networksConfig";
import { Button } from "antd";
import linkGenerator from "./paymentLinksGenerators";
import QRCodeComponent from "../shared/qrCodeComponent";
import { emailValidation, validateContentRule } from "../shared/validations";
import formatError from "../shared/formatError";
import checkImage from "../../assets/images/check-circle.png"
import { jwtDecode } from 'jwt-decode';
import PaymentLinkExpired from "./linkExpiredPage";
const ERC20_ABI = [
    "function balanceOf(address owner) view returns (uint256)",
    "function transfer(address to, uint amount) public returns (bool)"
];
async function getChainId(provider) {
    try {
        const chainId = await provider.request({ method: 'eth_chainId' });
        return chainId;
    } catch (error) {
        console.error('Error fetching chain ID:', error);
        throw error;
    }
}
export default function PaymentMethod() {
    const [loading, setLoading] = useState('');
    const [isValidPayment, setIsValidPayment] = useState(false)
    const [queryParams]=useSearchParams()
    const [details, setDetails] = useState({});
    const [saveDetails, setSaveDetails] = useState({
        email: '',
        hash: ''
    })
    const [validationErrors, setValidationErrors] = useState({
        email: '',
        hash: ''
    })
    const [errorMessage, setErrorMessage] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const { id } = useParams()
    const tokenTimeout = useRef(null);
    let walletType = useMemo(()=>{
        return queryParams.get("appname")
    },[queryParams]);
    const decodeToken = (token) => {
        try {
            return jwtDecode(token);
        } catch (error) {
            setErrorMessage("Invalid token format:", error.message);
            return null;
        }
    };

    const handleExpiration = () => setIsTokenExpired(true);

    const setTokenTimeout = (remainingTime) => {
        if (remainingTime > 0 && remainingTime * 1000 <= 2 ** 31 - 1) {
            tokenTimeout.current = setTimeout(handleExpiration, remainingTime * 1000);
        }
    };

    const checkTokenExpiration = () => {
        const token = queryParams.get("token");
        if (!token) return handleExpiration();

        const decodedToken = decodeToken(token);
        if (!decodedToken?.exp) return handleExpiration();

        const currentTime = Math.floor(Date.now() / 1000);
        const remainingTime = decodedToken.exp - currentTime;

        remainingTime > 0 ? setTokenTimeout(remainingTime) : handleExpiration();
    };

    useEffect(() => {
        checkTokenExpiration();
        return () => {
            if (tokenTimeout.current) {
                clearTimeout(tokenTimeout.current);
            }
        };
    }, []);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                setLoading("gettingData");
                const response = await getPaymentInfo(
                    "Merchant/PaymentDetailView",
                    id, walletType
                );
                setDetails(response);
                setIsValidPayment(true);
            } catch (error) {
                setErrorMessage(error.message);
            } finally {
                setLoading("");
            }
        };
        fetchDetails();
    }, [id]);
    const getAccount = async () => {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            return accounts[0];
        } catch (error) {
            setErrorMessage(formatError(error, 'contract'));
        }
    }
    async function switchNetwork(provider) {
        const networkForTransaction = chainsConfig[details?.network?.toLowerCase()];
        const networkForChainIDs = networkConfig[details?.network?.toLowerCase()]
        const chainId = await getChainId(provider)
        if (![networkForChainIDs.chainId, networkForChainIDs.hexId].includes(chainId?.toString())) {
            try {
                await provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [networkForTransaction],
                });
                return true
            } catch (error) {
                if (error.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [networkForTransaction],
                        });
                        return true
                    } catch (addError) {
                        setErrorMessage(formatError(addError, 'contract'));
                        return false;
                    }
                } else {
                    setErrorMessage(formatError(error, 'contract'));
                    return false;
                }
            }
        }
        return true
    }

    async function sendTransaction(provider, details, currency) {
        const browserProvider = new BrowserProvider(provider);
        const signer = await browserProvider.getSigner();
        const senderAddress = await signer.getAddress()
        const amount = parseUnits(details?.value?.toString(), Number(currency.decimals))
        let transactionResponse;
        const feeData = await browserProvider.getFeeData();
        const gasPrice = feeData.gasPrice;
        const naviteBalance = await browserProvider.getBalance(senderAddress);
        const handleTokenTransfer = async () => {
            const tokenContract = new Contract(currency?.address, ERC20_ABI, signer);
            async function estimateGasForTransfer() {
                const tx = {
                    from: senderAddress,
                    value: '0x0',
                    to: currency.address,
                    data: tokenContract.interface.encodeFunctionData('transfer', [details?.walletAddress, amount])
                };
                return await browserProvider.estimateGas(tx);
            }
            const tokenBalance = await tokenContract.balanceOf(senderAddress);
            if (tokenBalance < amount) {
                throw new Error(`Insufficient ${details?.currency} Balance: You do not have enough tokens.`);
            }
            const gasLimit = await estimateGasForTransfer();
            const gasCost = gasPrice * gasLimit;
            if (naviteBalance < gasCost) {
                const nativeSymbol = chainsConfig[details?.network?.toLowerCase()]?.nativeCurrency?.symbol;
                throw new Error(`Insufficient ${nativeSymbol} Balance: You do not have enough ${nativeSymbol} to cover gas fees.`);
            }
            transactionResponse = await tokenContract.transfer(details?.walletAddress, amount);
        }

        const nativeTransfer = async () => {
            const transactionParameters = {
                to: details?.walletAddress,
                value: amount,
            };
            const gasLimit = await browserProvider.estimateGas(transactionParameters);
            const totalCost = amount + (gasPrice * gasLimit);
            if (naviteBalance < totalCost) {
                throw new Error(`Insufficient Balance: You do not have enough ${details?.currency} to cover the transfer and gas fees.`);
            }
            transactionResponse = await signer.sendTransaction({ ...transactionParameters, gasLimit, gasPrice });
        }
        if (currency?.address) {
            await handleTokenTransfer()
        } else {
            await nativeTransfer()
        }
        return transactionResponse
    }

    const isInCorrectNetwork = async (provider, network, switchedNetwork) => {
        const chainId = await getChainId(provider)
        if (!switchedNetwork || ![network.chainId, network.hexId].includes(chainId?.toString())) {
            setLoading('')
            return true
        }
        return false;
    }
    const handleTransaction = async (transactionOf) => {
        const metamaskTransaction = async () => {
            setLoading('metamask')
            const provider = window.ethereum;
            if (provider?.isMetaMask) {
                await getAccount()
                const switched = await switchNetwork(provider);
                const network = networkConfig[details?.network?.toLowerCase()];
                const isWrongNetwork = await isInCorrectNetwork(provider, network, switched)
                if (isWrongNetwork) {
                    return;
                }
                const currency = network[details?.currency]
                try {
                    const response = await sendTransaction(provider, details, currency)
                    const receipt = await response.wait();
                    setSaveDetails((prev) => ({ ...prev, hash: receipt.hash }))
                } catch (error) {
                    setErrorMessage(formatError(error, 'contract'));
                }
            } else {
                setErrorMessage("Connector not found!")
            }
            setLoading('')
        }
        switch (transactionOf) {
            case 'metamask':
                await metamaskTransaction()
                break;
            case 'tron':
                break;
            default:
                break
        }
    }

    const validate = () => {
        let isValid = true;
        let isValidHash = true;
        const errors = {
            email: '',
            hash: ''
        }
        if (!saveDetails.email) {
            isValid = false;
            errors.email = 'Email is required!'
        } else {
            const emailErr = emailValidation(saveDetails.email) ? "Invalid email address" : "";
            isValid = !emailErr
            errors.email = emailErr
        }
        if (!saveDetails.hash) {
            isValid = false
            errors.hash = 'Hash is required!'
        } else {
            const hashErr = validateContentRule(saveDetails.hash) ? "Please enter valid content" : "";;
            isValidHash = hashErr ? false : true
            errors.hash = hashErr
        }
        if (!isValid) {
            setValidationErrors(errors)
        } else {
            setValidationErrors({ email: '', hash: '' })
        }
        return (isValid && isValidHash) ? true : false;
    }
    const handleSubmit = async () => {
        setLoading('saving')
        try {
            const isValid = validate()
            if (isValid) {
                const obj = {
                    paymentId: id,
                    email: saveDetails.email,
                    transactionHash: saveDetails.hash
                };
                await savePaymentInfo(obj, walletType);
                setShowSuccess(true)
            }
        } catch (err) {
            setErrorMessage(err.message)
        } finally {
            setLoading('')
        }
    }

    const handleInputChange = (value, field) => {
        setSaveDetails(prev => ({ ...prev, [field]: value }))
    }
    return (<>
        {!isTokenExpired && <div className="page-White">

            {loading === 'gettingData' && <div className="text-center content-center"><Loader /></div>}
            {errorMessage && !isTokenExpired && (
                <div className="alert-flex" style={{ width: "90%" }}>
                    <AppAlert
                        className="w-100 "
                        type="error"
                        description={errorMessage[0].toUpperCase() + errorMessage.slice(1)}
                        showIcon
                        closable={true}
                        onClose={() => setErrorMessage('')}
                    />
                    <span className="icon sm alert-close" onClick={() => setErrorMessage(null)}></span>
                </div>
            )}
            {showSuccess && <div className="content-center">
                <div className="payment-section">
                    <div className="text-center">
                        <img src={checkImage} className="check-img" alt="Success" />
                        <p className="declaration">Your payment has been successfully processed!</p>
                    </div>
                </div>
            </div>
            }

            {loading !== 'gettingData' && Object.keys(details)?.length>0 && !showSuccess && !isTokenExpired && <div className="payment-section">
                <div className="logo-flex mb-10"><h3>
                    {details?.merchantName}
                </h3></div>
                <div className="cust-qr" >
                    {!details?.id && <QRCodeComponent value={"Invalid QRCode"} className="qr-image" />}
                    {details?.id && <QRCodeComponent value={linkGenerator(details?.network?.toLowerCase())?.(details)} className="qr-image" />}
                    {details?.network && <p>Please switch to the {details?.network} network in your wallet before using the QR scanner for succesfull payment.</p>}
                </div>
                <div className="">
                    {details?.walletAddress && <CopyToClipboard text={details?.walletAddress} options={{ format: 'text/plain' }}>
                        <AppText copyable={{ tooltips: ['Copy', 'Copied'] }} className="address-text">{details?.walletAddress}</AppText></CopyToClipboard>}
                </div>
                {details?.id && <p className="balance-text mx-6">
                    {`${details?.amountWithCommission} ${details?.currency} (${details?.network})`}
                </p>}
                {details?.id && <p className="balance-text"><span className="text-gray">Order Id :</span> <span>{details?.id || "--"}</span></p>}
                <div className="hr-line"><hr /><p>OR</p><hr /></div>
                <h4>Select  Payment method</h4>
                <Button className="metamask-connect mb-18" onClick={() => handleTransaction('metamask')} disabled={!networkConfig[details?.network?.toLowerCase()]} loading={loading === 'metamask'}>
                    <div className="d-flex align-items-center gap-8">
                        <img src={metamask} alt="MetaMask" />
                        <p>Metamask Wallet {networkConfig[details?.network?.toLowerCase()] ? '(Supported)' : '(Not Supported)'}</p></div>
                    <img src={rightArrow} alt="right-arrow-icon" />
                </Button>
                <div className="hr-line"><hr /><p>OR</p><hr /></div>
                <h4 className="mb-22">After payment completion</h4>
                <div
                    className="p-relative mb-22"
                >
                    <label>Email <span className="text-red">*</span></label>
                    <input
                        className=""
                        placeholder="Enter Email"
                        value={saveDetails.email}
                        type="input"
                        maxLength={150}
                        onChange={(e) => handleInputChange(e.target.value, 'email')}
                    />
                    {validationErrors.email && <span className="required">{validationErrors.email}</span>}
                </div>
                <div
                    className="p-relative mb-22"
                >
                    <label>Hash <span className="text-red">*</span></label>
                    <input
                        className=""
                        placeholder="Enter hash"
                        type="input"
                        value={saveDetails.hash}
                        maxLength={150}
                        onChange={(e) => handleInputChange(e.target.value, 'hash')}
                    />
                    {validationErrors.hash && <span className="required">{validationErrors.hash}</span>}
                </div>
                <Button className="submit-btn" onClick={handleSubmit} disabled={!isValidPayment} loading={loading === 'saving'}>Submit</Button>
            </div>}
        </div>}
        {isTokenExpired && <PaymentLinkExpired />}
    </>
    );
};

