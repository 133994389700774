import networkConfig from "./networksConfig";
export function generateBitcoinLink(details, label, message) {
    if (label && message) {
        return `bitcoin:${details?.address}?amount=${details?.value}&label=${encodeURIComponent(label)}&message=${encodeURIComponent(message)}`;
    }
    return `bitcoin:${details?.address}?amount=${details?.value}`
}
export function generateEthereumLink(details) {
    const network = networkConfig[details?.network?.toLowerCase()]
    const currency = network[details?.currency]
    const address = encodeURIComponent(details?.walletAddress)
    const encodedValue = encodeURIComponent(details?.value);
    if (currency?.address) {
        return `ethereum:${currency.address}@${network.chainId}/transfer?address=${address}&uint256=${encodedValue}`
    }
    return `ethereum:${address}@${network.chainId}?value=${encodedValue}`
}

export function generateCardanoLink(details, message) {
    return `cardano:${details?.address}?amount=${details?.value}&message=${encodeURIComponent(message)}`;
}

export function generateStellarLink(details, memo) {
    return `stellar:${details?.address}?amount=${details?.value}&memo=${encodeURIComponent(memo)}`;
}

export function generateTronLink(details, message) {
    return `tron:${details?.address}?amount=${details?.value}&message=${encodeURIComponent(message)}`;
}

export function generateAlgorandLink(details, note) {
    return `algorand://${details?.address}?amount=${details?.value}&note=${encodeURIComponent(note)}`;
}

export default function linkGenerator(network) {
    const networkInLowerCase = network?.toLowerCase()
    if (!network) {
        return;
    }
    if (['erc-20', 'polygon amoy', 'sepolia', 'polygon', 'ethereum', 'arbitrum sepolia', 'base sepolia', 'holesky'].includes(networkInLowerCase)) {
        return generateEthereumLink
    }

    if (['trc-20'].includes(networkInLowerCase)) {
        return generateTronLink;
    }

    if (['cardano'].includes(networkInLowerCase)) {
        return generateCardanoLink;
    }

    if (['xlm'].includes(networkInLowerCase)) {
        return generateStellarLink;
    }

    if (['algo'].includes(networkInLowerCase)) {
        return generateAlgorandLink;
    }

    if (['btc'].includes(networkInLowerCase)) {
        return generateBitcoinLink;
    }

}