import { deriveErrorMessage } from "../shared/deriveErrorMessage";

export const getPaymentInfo = async (url, userId, type) => {
    const options = {
        method: 'GET'
    }
    const response = await fetch(`${type === 'Web3Payments' ? process.env.REACT_APP_WEB3_API_END_POINT : process.env.REACT_APP_API_END_POINT}${url}/${userId}`, options);
    const details = await response.json()
    if (response.status === 200) {
        return details
    }
    throw new Error(deriveErrorMessage(details))
}

export const savePaymentInfo = async (object, type) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(object)
    }
    const response = await fetch(`${type === 'Web3Payments' ? process.env.REACT_APP_WEB3_API_END_POINT : process.env.REACT_APP_API_END_POINT}Merchant/updatepaymentlinkstatus`, options);
    if (response.ok) {
        return true
    }
    const data = await response.json();
    throw new Error(deriveErrorMessage(data))

}