import expiredImg from "../../assets/images/expired.svg"

const PaymentLinkExpired =()=>{
    return(
        <>
        <div className="expired-content-center">
              <div className="payment-section p-45">
             <div className="text-center">
             <img src={expiredImg} className="expired-img" />
             <h2 className="declaration-heading">Your payment link has expired</h2>
             <p className="link-declaration"> Please contact us for a new link.</p>
              </div>
              </div>
            </div>
        </>
    )
}
export default PaymentLinkExpired;