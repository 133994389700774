import { deriveErrorMessage } from "./deriveErrorMessage";

const handleErrorMessage=(error)=>{
    if(typeof error.message!=='string'){
      return error.message
    }
    if(error.message.includes('INSUFFICIENT_FUNDS')){
      return "Insufficient funds!"
    }
    if(error.message.includes('REPLACEMENT_UNDERPRICED')){
      return "Underpriced!"
    }
    if(error.message.includes('TRANSACTION_REPLACED')){
      return "Transaction replaced!"
    }
    if(error.message.includes('UNPREDICTABLE_GAS_LIMIT')){
      return "Unpredictable gas limit!"
    }
    if(error.message.includes('NONCE_EXPIRED')){
      return "Nonce expired!"
    }
    return error.message || error?.toString()
  }
const formatError=(error, from,apiResponse) => {
    if (error && typeof error !== "string" && !from) {
        return deriveErrorMessage(error,apiResponse);
    }
    if (error && from === "contract") {
        return error?.details ||
        error?.cause?.reason ||
        error?.reason ||
        error?.error?.message ||
        error?.error?.error?.message ||
        error.fault ||
        handleErrorMessage(error) ||
        error;
    }
    return error?.message || error;
};

export default formatError;
