import React from 'react'
import { createBrowserRouter} from 'react-router-dom';
import PaymentMethod from './payments/paymentpage';

const router = createBrowserRouter([
    {
      path: "/:id",
      element: (
        <PaymentMethod/>
      ),
    }
  ]);


export default router;