const networkConfig = {
    'erc-20': {
        chainId: '1',
        hexId:'0x1',
        chainName: 'Ethereum Mainnet',
        'ETH': {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ['wss://ethereum-rpc.publicnode.com'],
        blockExplorerUrls: ['https://etherscan.io'],
        USDT: {
            "coinId": "tether",
            "decimals": 6,
            "name": "Tether",
            "symbol": "USDT",
            "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
        },
        USDC: {
            "coinId": "usd-coin",
            "address": "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
            "decimals": 6,
            "name": "USD Coin",
            "symbol": "USDC",
        }
    },
    'arbitrum sepolia': {
        chainId: '421614',
        hexId:'0x66eee',
        chainName: 'Arbitrum Sepolia',
        'ETH': {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ['https://endpoints.omniatech.io/v1/arbitrum/sepolia/public'],
        blockExplorerUrls: ['https://sepolia-explorer.arbitrum.io']
    },
    'base sepolia': {
        chainId: '84532',
        hexId:'0x14a34',
        chainName: 'Base Sepolia',
        'ETH': {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ['https://base-sepolia-rpc.publicnode.com'],
        blockExplorerUrls: ['https://sepolia.basescan.org/']
    },
    'holesky': {
        chainId: '17000',
        hexId:'0x4268',
        chainName: 'Ethereum Holesky',
        'ETH': {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ['https://ethereum-holesky-rpc.publicnode.com'],
        blockExplorerUrls: ['https://holesky.etherscan.io/']
    },
    //=========================== changes ==========================================================//
    'ethereum':{
        chainId: '1',
        chainName: 'Ethereum',
         hexId:"0x1",
        'ETH': {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ['wss://ethereum-rpc.publicnode.com'],
        blockExplorerUrls: ['https://etherscan.io'],
        USDT: {
            "coinId": "tether",
            "decimals": 6,
            "name": "Tether",
            "symbol": "USDT",
            "address": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        },
        USDC: {
            "coinId": "usd-coin",
            "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            "decimals": 6,
            "name": "USD Coin",
            "symbol": "USDC",
        }
    },
    'sepolia':{
        chainId: '11155111',
        chainName: 'Sepolia',
        hexId:"0xaa36a7",
        'ETH': {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ['https://rpc.sepolia.org'],
        blockExplorerUrls: ['https://sepolia.etherscan.io/'],
        USDT: {
            "coinId": "tether",
            "decimals": 6,
            "name": "Tether",
            "symbol": "USDT",
            "address": "0xbDeaD2A70Fe794D2f97b37EFDE497e68974a296d",
        },
        USDC: {
            "coinId": "usd-coin",
            "address": "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238",
            "decimals": 6,
            "name": "USD Coin",
            "symbol": "USDC",
        }
    },
    'polygon amoy':{
        chainId: '80002',
        chainName: 'Polygon Amoy',
         hexId:"0x13882",
        'MATIC': {
            name: 'Matic',
            symbol: 'MATIC',
            decimals: 18
        },
        rpcUrls: ['https://rpc-amoy.polygon.technology'],
        blockExplorerUrls: ['https://amoy.polygonscan.com/'],
        USDT: {
            "coinId": "tether",
            "decimals": 6,
            "name": "Tether",
            "symbol": "USDT",
            "address": "0xa19912a60EF2631Ae042C2c67Dd752D6bfB0f4E5",
        },
        USDC: {
            "coinId": "usd-coin",
            "address": "0x41E94Eb019C0762f9Bfcf9Fb1E58725BfB0e7582",
            "decimals": 6,
            "name": "USD Coin",
            "symbol": "USDC",
        },
        YBT: {
            "coinId": "YellowBlock Token",
            "address": "0x5123c99ee214e297aa96d92255fc6fc1c8c366e6",
            "decimals": 18,
            "name": "YellowBlock Token",
            "symbol": "YBT",
        }
    },
    'polygon':{
        chainId: '137',
        chainName: 'Polygon',
        hexId:"0x89",
        'MATIC': {
            name: 'Matic',
            symbol: 'MATIC',
            decimals: 18
        },
        rpcUrls: ['https://polygon-rpc.com'],
        blockExplorerUrls: ['https://polygonscan.com/'],
        USDT: {
            "coinId": "tether",
            "decimals": 6,
            "name": "Tether",
            "symbol": "USDT",
            "address": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        },
        USDC: {
            "coinId": "usd-coin",
            "address": "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
            "decimals": 6,
            "name": "USD Coin",
            "symbol": "USDC",
        }
    }
}

export const chainsConfig = {
    'erc-20': {
        chainId:'0x1',
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ['wss://ethereum-rpc.publicnode.com'],
        blockExplorerUrls: ['https://etherscan.io'],
    },
    'arbitrum sepolia': {
        chainId:'0x66eee',
        chainName: 'Arbitrum Sepolia',
        nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ['https://endpoints.omniatech.io/v1/arbitrum/sepolia/public'],
        blockExplorerUrls: ['https://sepolia-explorer.arbitrum.io']
    },
    'base sepolia': {
        chainId:'0x14a34',
        chainName: 'Base Sepolia',
        nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ['https://base-sepolia-rpc.publicnode.com'],
        blockExplorerUrls: ['https://sepolia.basescan.org/']
    },
    'holesky': {
        chainId:'0x4268',
        chainName: 'Ethereum Holesky',
        nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ['https://ethereum-holesky-rpc.publicnode.com'],
        blockExplorerUrls: ['https://holesky.etherscan.io/']
    },
    'ethereum': {
        chainId:'0x1',
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ['wss://ethereum-rpc.publicnode.com'],
        blockExplorerUrls: ['https://etherscan.io'],
    },
    'sepolia':{
        chainName: 'Sepolia',
        chainId:"0xaa36a7",
        nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ['https://rpc.sepolia.org'],
        blockExplorerUrls: ['https://sepolia.etherscan.io/'],
    },
    'polygon amoy':{
        chainName: 'Polygon Amoy',
         chainId:"0x13882",
        nativeCurrency: {
            name: 'Matic',
            symbol: 'MATIC',
            decimals: 18
        },
        rpcUrls: ['https://rpc-amoy.polygon.technology'],
        blockExplorerUrls: ['https://amoy.polygonscan.com/'],
    },
    'polygon':{
        chainName: 'Polygon',
        chainId:"0x89",
        nativeCurrency: {
            name: 'Matic',
            symbol: 'MATIC',
            decimals: 18
        },
        rpcUrls: ['https://polygon-rpc.com'],
        blockExplorerUrls: ['https://polygonscan.com/'],
    }
}
export default networkConfig