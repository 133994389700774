
const ERROR_MESSAGES = {
    DEFAULT: "Something went wrong, please try again!",
    UNHANDLED_EXCEPTION: "An unhandled exception occurred.",
    SERVICE_ISSUE: "Service issue, Please contact Administrator!",
    PERMISSION_DENIED: "You don't have permission, Please contact Administrator!",
    SERVER_DOWN: "Server down! Please contact Administrator!",
    VALIDATION_ERROR: "Please enter valid data"
};

export const deriveErrorMessage = (errorToDerive) => {
    const getErrorsMessage = (errors) =>
        Object.values(errors)
            .map((fieldErrors) =>
                typeof fieldErrors[0] === "string" ? fieldErrors[0] : ERROR_MESSAGES.VALIDATION_ERROR
            )
            .join(",");

    const { status, errors, title } = errorToDerive; // Modify this if you are using any client like axios. Currently, it is set to handle JSON response of fetch method

    switch (status) {
        case 522:
            return title || ERROR_MESSAGES.UNHANDLED_EXCEPTION;
        case 400:
            return errors
                ? getErrorsMessage(errors)
                : title || ERROR_MESSAGES.UNHANDLED_EXCEPTION;
        case 500:
            return title || ERROR_MESSAGES.SERVICE_ISSUE;
        case 403:
            return title || ERROR_MESSAGES.PERMISSION_DENIED;
        case 502: return ERROR_MESSAGES.SERVER_DOWN;
        case 503: return ERROR_MESSAGES.SERVER_DOWN
        default:
            return ERROR_MESSAGES.DEFAULT;
    }
}